<template>
    <!--AGENCY SECTION -->
    <section class="section-default section-agency">
      <div class="col-left">
        <div class="col-left-container">
          <h2>agence créative</h2>
          <p>Marjonat Distribution est devenu une agence dynamique de communication, de conception graphique et de stratégie publicitaire.</p>
        </div>
      </div>
      <div class="col-main">
          <div class="bg-main"></div>
          <div class="col-container">
              <div class="col-33">
                  <div class="col-item">
                      <p>animation <strong>3d</strong></p>
                      <router-link class="btn" :to="$route.path + '#anim'">découvrir</router-link>
                  </div>
              </div>
              <div class="col-33">
                  <div class="col-item">
                      <p>PACKSHOT <strong>produits</strong></p>
                      <router-link class="btn" :to="$route.path + '#products'">découvrir</router-link>
                  </div>
              </div>
              <div class="col-33">
                  <div class="col-item">
                      <p>motion <strong>design</strong></p>
                      <router-link class="btn" :to="$route.path + '#design'">découvrir</router-link>
                  </div>
              </div>
              <!-- <div class="col-25">
                  <div class="col-item">
                      <p>applications de <strong>vente</strong></p>
                      <router-link class="btn" :to="$route.path + '#appli'">découvrir</router-link>
                  </div>
              </div> -->
          </div>
      </div>
    </section>

    <!-- ANIM -->
    <section id="anim" class="section-default section-anim">
      <div class="col-left">
        <div class="col-left-container">
          <p>L’animation 3D est un film en images de synthèse 3D qui permet de mettre en mouvement votre produit, de manière réaliste, pédagogique, ludique, marketing et commerciale afin de le mettre en valeur et de le présenter en détails. Elle offre la possibilité de rentrer au cœur du sujet (grâce à des vues en coupe, à des éclatés ou à des mises en transparence).</p>
        </div>
      </div>
      <div class="col-main">
          <div class="col-50">
              <h2>ANIMATIONS <strong>3D</strong></h2>
              <p>Présentation <span>produits</span></p>
              <div class="sep-agency"></div>
              <p>Mise en avant d’<span>avantages concurrentiels</span></p>
              <div class="sep-agency"></div>
              <p><span>Promotions commerciales</span> en RDV/sur les salons</p>
              <div class="sep-agency"></div>
              <p><span>Promotions</span> sur internet & les réseaux sociaux</p>
          </div>
          <div class="col-50">
              <img class="img-agency" src="./../assets/img-anim.png" alt="animations" />
          </div>
      </div>
    </section>

    <!-- PRODUCTS -->
    <section id="products" class="section-default section-products">
      <div class="col-left">
        <div class="col-left-container">
            <h2>PACKSHOT <strong>PRODUITS</strong></h2>    
          <p>Le packshot produit consiste à créer des objets virtuels en 3D et les mettre en situation de la manière la plus réaliste possible. Chaque détail est étudié pour la composition de l’illustration 3D qu’il s’agisse de la lumière, des textures, des formes de chaque élément et de leur intégration dans la scène 3D.</p>
        </div>
      </div>
      <div class="col-main">
          <div class="col-50">
              <h2>PACKSHOT <strong>PRODUITS</strong></h2>
              <p>Packshot <span>produit</span></p>
              <div class="sep-agency"></div>
              <p>Illustation de<span> document commercial</span></p>
              <div class="sep-agency"></div>
              <p><span>Publicité</span></p>
              <div class="sep-agency"></div>
              <p>Habillage de <span>packaging</span></p>
          </div>
          <div class="col-50">
              <img class="img-agency" src="./../assets/products-img.png" alt="produits" />
          </div>
      </div>
    </section>

    <!-- DESIGN -->
    <section id="design" class="section-default section-design">
      <div class="col-left">
        <div class="col-left-container">
            <h2>MOTION <strong>DESIGN</strong></h2>
          <p>Le Motion-Design est une forme d’art visuel consistant à créer des vidéos animées figuratives généralement en 2D. Il implique d’utiliser le mouvement comme principal outil graphique et artistique.</p>
        </div>
      </div>
      <div class="col-main">
          <div class="col-50">
              <h2>MOTION <strong>DESIGN</strong></h2>
              <p>Présentation <span>produit/service</span></p>
              <div class="sep-agency"></div>
              <p><span>Publicité</span> promotionnelle</p>
              <div class="sep-agency"></div>
              <p>Mise en avant d’<span>avantages concurrentiels</span></p>
              <div class="sep-agency"></div>
              <p><span>Promotions</span> sur internet & les réseaux sociaux</p>
          </div>
          <div class="col-50">
              <img class="img-agency" src="./../assets/design-img.png" alt="motion design" />
          </div>
      </div>
    </section>

    <!-- APP -->
    <!-- <section id="appli" class="section-default section-app">
      <div class="col-left">
        <div class="col-left-container">
            <h2>APPLICATIONS DE <strong>VENTE</strong></h2>
          <p>Les applications mobiles à destination des forces de vente sont des espaces documentaires mobiles 100% personnalisables qui permettent à vos commerciaux et représentants de facilement avoir accès, en un clic, à vos outils marketing pour leur permettre de réussir leurs présentations commerciales ou leurs rendez-vous en magasins.</p>
        </div>
      </div>
      <div class="col-main">
          <div class="col-50">
              <h2>APPLICATIONS DE <strong>VENTE</strong></h2>
              <p>Catalogue produits pour les <span>forces de vente</span></p>
              <div class="sep-agency"></div>
              <p>Présentation <span>produit</span></p>
              <div class="sep-agency"></div>
              <p>Argumentaire de <span>vente</span></p>
              <div class="sep-agency"></div>
              <p>Espace <span>documentaire</span></p>
          </div>
          <div class="col-50">
              <img class="img-agency" src="./../assets/app-img.png" alt="applications" />
          </div>
      </div>
    </section> -->
</template>

<script>
export default {
    name : "Agency"
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    .section-agency .col-left {
        background:$mainColor;
    }

    .section-agency .bg-main {
        background-image: url('./../assets/banner-agency.jpg');
    }

    .col-main .col-50 {
        width:46%;
        margin:0 2%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .img-agency {
        max-width:90%;
    }

    .col-main .col-50 h2 {
        color:$mainColor;
        text-transform: uppercase;
        font-weight:400;
        font-size:40px;
        margin-bottom:80px;
        text-align: center;
    }

    .col-main .col-50 p {
        text-align: center;
        font-size:20px;
        color:$secondColor;
        line-height: 1.5em;
    }

    .col-main .col-50 p span {
        color:$mainColor;
    }

    .sep-agency {
        height:1px;
        width:200px;
        margin: 40px auto;
        background:$mainColor;
    }

    /* ANIM */
    .section-anim {
        background-image: url('./../assets/bg-anim.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
    }

    .section-anim .col-left {
        background:$secondColor;
    }

    /* PRODUCTS */
    .section-products {
        background-image: url('./../assets/products-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
    }

    .section-products .col-left {
        background:$mainColor;
    }

    /* DESIGN */
    .section-design {
        background-image: url('./../assets/design-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
    }

    .section-design .col-left {
        background:$secondColor;
    }

    /* APP */
    .section-app {
        background-image: url('./../assets/app-bg.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center right;
    }

    .section-app .col-left {
        background:$mainColor;
    }

    .col-left h2 {
        display:none;
    }

    @media screen and (max-width:1100px) {
        .section-agency .col-left {
            padding-top:160px;
        }

        .section-agency .col-main {
            background-image: url('./../assets/banner-agency.jpg');
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .col-main {
            flex-direction: column;
        }

        .col-main .col-50 {
            width:98%;
            margin:20px auto;
        }

        .col-main h2 {
            display: none;
        }

        .col-left h2 {
            display:block;
            font-size:30px;
            text-align: center;
        }
    }


</style>